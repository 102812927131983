import React from "react"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"
import TwitterLogo from "./twitter-logo"

type SocialMediaShareProps = {
  title: string
  url: string
}

const SocialMediaShare = (props: SocialMediaShareProps) => {
  return (
    <div className="flex flex-row gap-x-2">
      <div>
        <EmailShareButton url={props.url} subject={props.title}>
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
      </div>
      <div>
        <FacebookShareButton url={props.url} title={props.title}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
      </div>
      <div>
        <TwitterShareButton url={props.url} title={props.title}>
          <TwitterLogo></TwitterLogo>
        </TwitterShareButton>
      </div>
      <div>
        <WhatsappShareButton url={props.url} title={props.title}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </div>
    </div>
  )
}
export default SocialMediaShare
