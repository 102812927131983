import React from "react"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"

import Layout from "../components/layout"
import Head from "../components/header/head"
import { HEADER_PAGE_NAME_SUHBAH, HEADER_PAGE_NAME_ENSEIGNEMENT, HEADER_PAGE_NAME_ARTICLES } from "../utils/constants"
import { POST_TYPES, PostType } from "../common/post-type.model"
import { isBrowser } from "../utils/utils"
import SocialMediaShare from "../components/social-media/social-media-share"

// Can't use useStaticQuery inside of template (because it is static so it can't have variables)
// We define the query like so outside of the component²
// Gatsby will then run the query and provide it as props to the component in the data field
// The query receives variables from the context  passed from gatsbynode create pages

export const query = graphql`
  query($slug: String) {
    contentfulPost(slug: { eq: $slug }) {
      title
      city
      publishedDate(formatString: "DD/MM/YYYY", locale: "fr")
      slug
      originalAuthor {
        name
        bio
      }
      category {
        name
      }
      postType {
        typeId
        name
      }
      tags
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            file {
              url
            }
            description
            title
          }
        }
      }
    }
  }
`

type PostTemplateProps = {
  data: any
}

const PostTemplate = (props: PostTemplateProps) => {
  let i = 0
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text: string) => <span className="font-semibold">{text}</span>,
      [MARKS.ITALIC]: (text: string) => <span className="italic">{text}</span>,
      [MARKS.UNDERLINE]: (text: string) => <span className="underline">{text}</span>,
      [MARKS.CODE]: (text: string) => <span className="font-system">{text}</span>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const reference = props.data.contentfulPost.body.references[i]
        i++
        if (reference) {
          const alt = reference.description
          const url = reference.file.url
          return (
            <div className="w-full h-full bg-gray-100 mb-4 relative">
              <img className="w-full h-full" alt={alt} src={url} srcSet={url}></img>
              {reference.description ? (
                <div className="absolute bottom-0 p-1 bg-white bg-opacity-30 w-full rounded-t-sm text-xs text-gray-700">
                  {reference.description}
                </div>
              ) : (
                <></>
              )}
            </div>
          )
        }
        return <></>
      },
      [BLOCKS.HEADING_1]: (node: any, children: any) => {
        return <div className="text-sm text-center">{children}</div>
      },
      [BLOCKS.HEADING_2]: (node: any, children: any) => {
        return (
          <div id="heading-2" className="text-xbase md:text-xl text-gray-400 mt-12 mb-6">
            {children}
          </div>
        )
      },
      [BLOCKS.HEADING_3]: (node: any, children: any) => {
        return <div className="text-xl md:text-2xl mt-12 mb-6">{children}</div>
      },
      [BLOCKS.HEADING_4]: (node: any, children: any) => {
        return <div className="text-xbase md:text-xl mt-8 font-serif text-center">{children}</div>
      },
      [BLOCKS.HEADING_5]: (node: any, children: any) => {
        return <div className="text-base md:text-xl mb-4 mt-2 font-serif text-center">{children}</div>
      },
      [BLOCKS.HEADING_6]: (node: any, children: any) => {
        return (
          <div dir="rtl" className="text-xbase md:text-xl mt-6 mb-3 text-arabic text-center">
            {children}
          </div>
        )
      },
      [BLOCKS.QUOTE]: (node: any, children: any) => {
        return (
          <div className="text-xbase md:text-xl my-8 ml-4 md:ml-8 border-l-4 border-gray-300 pl-4 pr-8 md:pr-16 py-px">
            {children}
          </div>
        )
      },
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
        return <div className="font-serif my-4 text-xbase md:text-xl">{children}</div>
      },
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        const isAnchor =
          children &&
          children[0] &&
          typeof children[0] === "string" &&
          children[0].charAt(0) === "(" &&
          !isNaN(+children[0].charAt(1))
        if (isAnchor) {
          return (
            <a href={node.data.uri} rel="noreferrer" className="hover:text-blue-700 text-blue-500">
              <sup>{children}</sup>
            </a>
          )
        }
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer" className="hover:text-blue-700 text-blue-500">
            {children}
          </a>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: any) => {
        return (
          <div className="my-12 text-xbase md:text-xl font-serif">
            <span className="font-semibold">{post.originalAuthor ? post.originalAuthor.name : ""},</span>
            <span className="ml-2">{post.originalAuthor ? post.originalAuthor.bio : ""}</span>
          </div>
        )
      },
      [BLOCKS.UL_LIST]: (node: any, children: any) => {
        return (
          <ul className="pb-4">
            {children.map((node: any, index: number) => {
              return (
                <li className="flex flex-row -my-4" key={index}>
                  <div className="font-serif my-4 text-xbase md:text-xl mr-2">&#x2022; </div>
                  {node.props.children}
                </li>
              )
            })}
          </ul>
        )
      },
      [BLOCKS.OL_LIST]: (node: any, children: any) => {
        return (
          <ol className="pb-4">
            {children.map((node: any, index: number) => {
              return (
                <li className="flex flex-row -my-4" key={index}>
                  <div className="font-serif my-4 text-xbase md:text-xl mr-2">{index + 1}- </div>
                  {node.props.children}
                </li>
              )
            })}
          </ol>
        )
      },
    },
  }
  const post = props.data.contentfulPost
  let headerPageName
  switch (post.postType?.typeId) {
    case POST_TYPES.SUHBAH:
      headerPageName = HEADER_PAGE_NAME_SUHBAH
      break
    case POST_TYPES.ENSEIGNEMENT:
      headerPageName = HEADER_PAGE_NAME_ENSEIGNEMENT
      break
    case POST_TYPES.ARTICLE:
      headerPageName = HEADER_PAGE_NAME_ARTICLES
      break
    default:
      headerPageName = ""
      break
  }
  let postLink = ""
  if (isBrowser) {
    postLink = `${window.location.origin}/posts/${post.slug}`
  }
  return (
    <Layout headerPageName={headerPageName}>
      <Head title={post.title} description={post.title} />
      <div className="max-w-116 mx-auto p-2">
        <div className="mb-2">
          <div className="flex flex-col align-middle text-center">
            <div className="mb-8 text-sm md:text-lg font-sans font-semibold">
              <span className="ml-1 text-rose-700">{post.category?.name.toUpperCase()}</span>
            </div>
            <h1 className="text-gray-700 text-4xl md:text-5xl font-semibold mb-8">{post.title}</h1>
          </div>

          <div className="flex flex-row">
            <div className="my-auto text-gray-400 text-xs md:text-lg">
              {post.city
                ? `${post.city}${post.publishedDate ? ", " + post.publishedDate : ""}`
                : `${post.publishedDate ? post.publishedDate : ""}`}
            </div>
            <div className="ml-auto">
              <SocialMediaShare url={postLink} title={post.title}></SocialMediaShare>
            </div>
          </div>
        </div>
        {isBrowser ? renderRichText(post.body, options as any) : ""}
        {/* <hr className="mt-8 border border-gray-400 bg-gray-400 w-4/6"></hr> */}
        <div className="flex flex-row flex-wrap gap-2">
          {(post.tags || []).map((tag: string, index: number) => {
            return (
              <button
                key={`${tag}-${index}`}
                className={`px-4 py-1 bg-gray-100 text-gray-400 rounded-sm text-xbase outline-none focus:outline-none`}
              >
                {tag}
              </button>
            )
          })}
        </div>
        <div className="flex flex-row-reverse mt-12">
          <SocialMediaShare url={postLink} title={post.title}></SocialMediaShare>
        </div>
      </div>
    </Layout>
  )
}

export default PostTemplate
